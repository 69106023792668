<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Card",
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
})
</script>

<template>
  <div class="rounded-md bg-white dark:bg-zinc-800 shadow">
    <div v-if="title" class="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-zinc-700">
      <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">{{ title }}</h3>
      <p v-if="description" class="mt-1 text-sm text-gray-500 dark:text-gray-400">{{ description }}</p>
    </div>
    <div class="px-2 py-4 sm:p-4">
      <slot/>
    </div>
    <div v-if="$slots['footer']" class="bg-gray-50 dark:bg-zinc-900/50 px-4 py-4 sm:px-6 rounded-b-md">
      <slot name="footer"/>
    </div>
  </div>
</template>

<style scoped>

</style>